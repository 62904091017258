var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "faturamento" },
    [
      _c("HeadbarPage", { attrs: { title: "Relatórios > Faturamento" } }),
      _c(
        "v-card",
        { staticClass: "mt-5" },
        [
          _c(
            "v-card-text",
            {
              staticClass:
                "primary--text text-left font-weight-black display-1 px-3"
            },
            [
              _c("v-icon", { staticClass: "ma-2", attrs: { large: "" } }, [
                _vm._v(" mdi-progress-close ")
              ]),
              _vm._v(_vm._s(_vm.title) + " ")
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "text-left px-3" },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "3" } },
                    [
                      _c("v-select", {
                        attrs: {
                          solo: "",
                          items: _vm.itemsType,
                          label: "Selecione o tipo de relatório",
                          "item-text": "name",
                          "item-value": "value"
                        },
                        model: {
                          value: _vm.selectedType,
                          callback: function($$v) {
                            _vm.selectedType = $$v
                          },
                          expression: "selectedType"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "2" } },
                    [
                      _c(
                        "v-menu",
                        {
                          ref: "menu",
                          attrs: {
                            solo: "",
                            "close-on-content-click": false,
                            transition: "scale-transition",
                            "offset-y": "",
                            "min-width": "auto"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function({ on, attrs }) {
                                return [
                                  _c(
                                    "v-text-field",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          attrs: {
                                            solo: "",
                                            value: _vm.formatDate(
                                              _vm.dateSelected
                                            ),
                                            "prepend-icon": "mdi-calendar",
                                            readonly: ""
                                          }
                                        },
                                        "v-text-field",
                                        attrs,
                                        false
                                      ),
                                      on
                                    )
                                  )
                                ]
                              }
                            }
                          ]),
                          model: {
                            value: _vm.menuDate,
                            callback: function($$v) {
                              _vm.menuDate = $$v
                            },
                            expression: "menuDate"
                          }
                        },
                        [
                          _c("v-date-picker", {
                            staticClass: "picker-faturamento",
                            attrs: {
                              locale: "pt-br",
                              solo: "",
                              type: "month",
                              "active-picker": _vm.activePicker
                            },
                            on: {
                              "update:activePicker": function($event) {
                                _vm.activePicker = $event
                              },
                              "update:active-picker": function($event) {
                                _vm.activePicker = $event
                              },
                              input: function($event) {
                                _vm.menuDate = false
                              }
                            },
                            model: {
                              value: _vm.dateSelected,
                              callback: function($$v) {
                                _vm.dateSelected = $$v
                              },
                              expression: "dateSelected"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.superUsuario,
                          expression: "superUsuario"
                        }
                      ]
                    },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          solo: "",
                          small: "",
                          label: "Selecione Apresentante",
                          items: _vm.apresentantes,
                          filter: _vm.customFilter,
                          "item-value": "cd_apresentante",
                          "item-text": "cd_apresentante",
                          spellcheck: "false",
                          loading: _vm.loading
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "item",
                            fn: function(data) {
                              return [
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c("v-list-item-title", [
                                      _c(
                                        "span",
                                        {
                                          class: {
                                            "text-gray": !data.item.st_ativo
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              data.item.nome_exibicao ||
                                                data.item.nm_apresentante
                                            )
                                          )
                                        ]
                                      ),
                                      _c("br"),
                                      _c("div", [
                                        _c("small", [
                                          _vm._v(
                                            "Código apresentante: " +
                                              _vm._s(data.item.cd_apresentante)
                                          )
                                        ])
                                      ])
                                    ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ]),
                        model: {
                          value: _vm.apresentanteSelecionado,
                          callback: function($$v) {
                            _vm.apresentanteSelecionado = $$v
                          },
                          expression: "apresentanteSelecionado"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            large: "",
                            disabled:
                              !_vm.selectedType ||
                              !_vm.dateSelected ||
                              !_vm.apresentanteSelecionado
                          },
                          on: { click: _vm.gerarRelatorio }
                        },
                        [_vm._v(" Gerar Relatório ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "my-5" },
        [
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              loading: _vm.loadingTable,
              options: _vm.options,
              items: _vm.itens,
              "server-items-length": _vm.totalTitulos,
              "footer-props": {
                itemsPerPageOptions: [10, 20, 50, 100],
                itemsPerPageText: "Itens por página:"
              }
            },
            on: {
              "update:options": function($event) {
                _vm.options = $event
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: `item.dt_cadastro`,
                  fn: function({ item }) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(_vm.formatDataVencimento(item.dt_cadastro)) +
                          " "
                      )
                    ]
                  }
                },
                {
                  key: `item.dt_vencimento`,
                  fn: function({ item }) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(_vm.formatDataVencimento(item.dt_vencimento)) +
                          " "
                      )
                    ]
                  }
                },
                {
                  key: `item.nu_identificacao_devedor`,
                  fn: function({ item }) {
                    return [
                      _c("span", [
                        _vm._v(" " + _vm._s(_vm.formatDocumento(item)) + " ")
                      ])
                    ]
                  }
                },
                {
                  key: `item.dt_historico`,
                  fn: function({ item }) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(_vm.formatDataVencimento(item.dt_historico)) +
                          " "
                      )
                    ]
                  }
                },
                {
                  key: `item.dt_atualizacao`,
                  fn: function({ item }) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.formatDataVencimento(item.dt_atualizacao)
                          ) +
                          " "
                      )
                    ]
                  }
                }
              ],
              null,
              true
            )
          }),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "d-flex justify-end ma-5" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        large: "",
                        disabled:
                          !_vm.selectedType ||
                          !_vm.dateSelected ||
                          !_vm.apresentanteSelecionado
                      },
                      on: {
                        click: function($event) {
                          _vm.modalAvisoConfirmacao = true
                        }
                      }
                    },
                    [_vm._v(" Exportar por e-mail ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.modalAvisoConfirmacao
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "600px",
                scrollable: "",
                color: "background",
                transition: "dialog-bottom-transition"
              },
              model: {
                value: _vm.modalAvisoConfirmacao,
                callback: function($$v) {
                  _vm.modalAvisoConfirmacao = $$v
                },
                expression: "modalAvisoConfirmacao"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    { staticClass: "headline primary--text" },
                    [
                      _c("v-icon", { staticClass: "mr-5" }, [
                        _vm._v("mdi-alert-circle")
                      ]),
                      _vm._v(" Relatorio de Faturamento ")
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "12" } }, [
                            _c("strong", [_vm._v("Atenção!")]),
                            _vm._v(
                              " Este relatorio sera enviado no e-mail: " +
                                _vm._s(_vm.userEmail) +
                                " "
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", text: "" },
                          on: {
                            click: function($event) {
                              _vm.modalAvisoConfirmacao = false
                            }
                          }
                        },
                        [_vm._v(" Cancelar ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", text: "" },
                          on: { click: _vm.enviarRelatorio }
                        },
                        [_vm._v(" Enviar ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.snackbar
        ? _c("Snackbar", {
            attrs: {
              mostrarSnackbar: _vm.snackbar,
              corSnackbar: _vm.colorSnackbar,
              mensagemSnackbar: _vm.mensagemSnackbar
            },
            on: {
              fecharSnackbar: function($event) {
                _vm.snackbar = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }